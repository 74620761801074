export default [
  { name: '车辆发票上传', path: '/upload-vehicleInvoice' },
  { name: '低满工单', path: '/lowFullWorkOrder' },
  { name: '智能巡店', path: '/dshowRoomh5' },
  { name: '审批中心(总部)', path: '/approve-manage-factory' },
  { name: '话术库', path: '/talk-skill' },
  { name: '提车礼品报缺', path: '/gift-deletion-list' },
  { name: '离职继承', path: '/leave-job' },
  { name: '交付提醒', path: '/deliverRemind-list' },
  { name: '试驾车点检', path: '/inspection-list' },
  { name: '无效管理', path: '/invalid-manage' },
  { name: '试驾报告', path: '/test-drive-report' },
  { name: '试驾礼抽奖', path: '/receiveGift' },
  { name: '交付助手', path: '/delivery-assistant/home' },
  { name: '交付助手(总部)', path: '/delivery-assistant-portal/home' },
  { name: '销售助手', path: '/salesAssistant' },
  { name: '销售助手(总部)', path: '/sales-assistant-portal/home' },
  { name: '我的待办(总部)', path: '/my-todo-portal' },
  { name: '企微任务', path: '/todo-chat-tasks' },
  { name: '我的待办', path: '/my-todo' },
  { name: '新建线索', path: '/create-clue' },
  { name: '线索明细', path: '/clue-list' },
  { name: '商机明细', path: '/opportunityManage' },
  { name: '公共线索', path: '/clue-distribution' },
  { name: '订单明细', path: '/order-list' },
  { name: '待分配线索', path: '/clue-distribution' },
  { name: '线索战败管理', path: '/clue-defeat' },
  { name: '预约明细', path: '/appointment' },
  { name: '交车明细', path: '/deliver-manage' },
  { name: '试驾车管理', path: '/car-manage' },
  { name: '目标进度', path: '/goal-progress' },
  { name: '素材库', path: '/material' },
  { name: '线索培育', path: '/clue-incubation' },
  { name: '线索转入', path: '/leadChangeInto' },
  { name: '库存查询', path: '/stock' },
  { name: '特殊车配置单', path: '/special-car-order-config' },
  { name: '特殊车库存', path: '/special-car-stock' },
  { name: '批量线索任务', path: '/batch-clue-task' },
]
