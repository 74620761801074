import i18nFun from '../router_i18n'
const appName = i18nFun['任务明细']
const routerPath = 'salesAssistant'

const routes = [
  {
    path: '/taskManage',
    name: 'TaskManage',
    component: () => import('@/modules/taskManage/index.vue'),
    meta: {
      title: i18nFun['任务明细'],
      appName,
      isAppIndex: true,
      routerPath
    }
  },
  {
    path: '/taskManage/follow',
    name: 'TaskManageFollow',
    component: () => import('@/modules/taskManage/listForFollow.vue'),
    meta: {
      title: i18nFun['跟进明细'],
      appName,
      isAppIndex: true,
      routerPath
    }
  },
]

export default routes
